import * as React from 'react';
import { siteMetadata } from '../../../../gatsby-config';
import { graphql } from 'gatsby';

import DataService from '../../../services/dataService';

import Layout from '../Layout';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import {
    Tooltip,
    IconButton,
    Icon,
    MenuItem,
    Table,
    TableHead,
    TableBody,
    TableCell,
    Select,
    TableRow,
    TextField,
    Button,
    Checkbox
} from '@mui/material';
import { navigate } from 'gatsby';

import { useDispatch, useSelector } from 'react-redux';
import { setEditableForm, setUpdatingData } from '../../../redux/admin/adminSlice';


const CreateFormField = ({ field, fieldIndex, handleUpdateFormFields, handleDeleteRow }) => {

    const [fieldName, setFieldName] = React.useState(field.name);
    const [fieldType, setFieldType] = React.useState(field.type);
    const [fieldOptions, setFieldOptions] = React.useState(field.options?.join(','));
    const [defaultFieldValue, setDefaultFieldValue] = React.useState(field.defaultValue);

    const [fieldTypes, setFieldTypes] = React.useState([
        'text',
        'textarea',
        'select',
        'checkbox'
    ]);

    React.useEffect(() => {

        // console.log(stateChanged);
        updateFormFields();
    }, [fieldName, fieldType, fieldOptions, defaultFieldValue]);

    const updateFormFields = () => {

        handleUpdateFormFields(
            {
                name: fieldName,
                type: fieldType,
                options: fieldOptions.split(','),
                defaultValue: defaultFieldValue
            },
            fieldIndex
        )
    }

    const prepareFieldOptions = (optionsString) => {
        
        setFieldOptions(optionsString);
    }

    const deleteRow = () => {

        handleDeleteRow(fieldIndex);
    }

    return (
        <TableRow 
            style={{
                justifyContent: 'space-around',
                backgroundColor: (fieldIndex % 2 == 0) ? 'whitesmoke' : 'white'
            }}
        >
            <TableCell style={{ textAlign: 'center' }}>
                <TextField 
                    value={fieldName} 
                    onChange={(e) => {
                        setFieldName(e.target.value); 
                        // setStateChanged(!stateChanged)
                        // updateFormFields();
                    }}
                />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
                <Select 
                    value={fieldType}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                        setFieldType(e.target.value);
                        // setStateChanged(!stateChanged)
                        // updateFormFields();
                    }}
                >
                    {fieldTypes.map( type => (
                        <MenuItem value={type}>
                            {type}

                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
                {(() => {
                    switch(field.type) {
                        case 'select':
                            return (
                                <TextField 
                                    onChange={(e) => {
                                        setFieldOptions(e.target.value);
                                    }}
                                    value={fieldOptions}
                                />
                            )
                        default:
                            return (
                                <p>-</p>
                            )
                    }
                })()}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
                {(() => {
                    switch(field.type) {
                        case 'select':
                            return (
                                <Select 
                                    value={field.defaultValue}
                                    onChange={(e) => {
                                        setDefaultFieldValue(e.target.value);
                                        // setStateChanged(!stateChanged)
                                        // updateFormFields();
                                    }}
                                >
                                    <MenuItem value={null} placeholder={"None"}>None</MenuItem>
                                    {field.options.map( option => (
                                        <MenuItem value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            );

                        case 'checkbox': 
                            return (
                                <Checkbox 
                                    checked={field.defaultFieldValue}
                                />
                            );

                        default:
                            return (
                                <p>-</p>
                            );
                    }
                })()}
            </TableCell>
            <TableCell>
                <Tooltip 
                    title="Remove" 
                    placement='right'
                    className="hi"
                >
                    <IconButton onClick={() => {

                        const confirm = window.confirm("Are you sure you want to delete this field");

                        if (confirm) {
                            deleteRow();
                        }
                    }}>
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

const CreateForm = ({ data, location, pageContext }) => {

    const dispatch = useDispatch();

    const [formTitle, setFormTitle] = React.useState('');

    const [formFields, setFormFields] = React.useState([{name: "", type: "", options: [], defaultValue: ""}]);

    const [triggerUpdate, setTriggerUpdate] = React.useState(false);

    const [canCreateForm, setCanCreateForm] = React.useState(false);

    React.useEffect(() => {

        if (formTitle != null) {
            setCanCreateForm(true);
            return;
        }

        setCanCreateForm(true);
    }, [formFields]);
    
    
    const handleUpdateFormFields = (fieldObject, fieldIndex) => {

        let newFields = formFields;
        newFields[fieldIndex] = fieldObject;
        setFormFields([...newFields]);
        // setUpdateButtonDisabled(false);
    }

    const handleDeleteRow = (fieldIndex) => {

        let newFields = formFields;
        newFields.splice(fieldIndex, 1);
        setFormFields([...newFields]);
        setTriggerUpdate(!triggerUpdate);
    }

    const addField = () => {

        let newFields = formFields;
        let newField = {name: '', type: '', options: [], defaultValue: ""};
        console.log(newFields);
        newFields.push(newField);
        setFormFields([...newFields]);
    }

    const createForm = () => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.create({
            title: formTitle,
            formfields: JSON.stringify(formFields),
            created: new Date().toISOString(),
            site: data.datoCmsWebsite.id.split('-')[1],
            item_type: { type: 'item_type', id: siteMetadata.datoCmsModelIds.Form.toString() }
        }).then( newForm => {
            navigate('/admin/forms/', { state: { reload: true } });
            dispatch(setUpdatingData({ updatingData: false }));
        })
    }

    return (
        <Layout>
            <div style={{
                backgroundColor: 'white',
                WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.40)',
                boxShadow: '1px 2px 14px 1px rgba(0,0,0,0.40)',
                padding: '2rem'
            }}>
                <h2 className="text-xl font-semibold text-center" style={{ fontFamily: 'monospace' }}>Create Form</h2>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TextField 
                        value={formTitle}
                        placeholder={"Title"}
                        onChange={(e) => {
                            setFormTitle(e.target.value);
                        }}
                        style={{
                            padding: '1rem',
                            paddingLeft: 0
                        }}
                    />
                    <Tooltip 
                        title={"Add Field"} 
                        placement='bottom'
                        className="hi"
                        style={{
                            top: '50%',
                            transform: 'translate(0, -50%)',
                            marginLeft: 'auto'
                        }}
                    >
                        <IconButton 
                            onClick={() => {
                                addField();
                            }}
                            style={{
                                height: 50,
                                width: 50,
                                border: '1px solid gray'
                            }}
                        >
                            <AddIcon style={{}} />
                        </IconButton>
                    </Tooltip>
                </div>
                <Table>
                    <TableHead
                        style={{
                            
                        }}
                    >
                        <TableRow 
                            style={{ 
                                disply: 'flex', 
                                justifyContent: 'space-around',
                                backgroundColor: '#45425a'
                            }}
                        >
                            <TableCell 
                                style={{ 
                                    textAlign: 'center', 
                                    color: 'white',
                                    borderTopLeftRadius: 4
                                }}
                            >
                                Field Name
                            </TableCell>
                            <TableCell 
                                style={{ 
                                    textAlign: 'center', 
                                    color: 'white',
                                }}
                            >
                                Field Value
                            </TableCell>
                            <TableCell
                                style={{ 
                                    textAlign: 'center', 
                                    color: 'white',
                                }}
                            >
                                Options (comma separated values)
                            </TableCell>
                            <TableCell
                                style={{ 
                                    textAlign: 'center', 
                                    color: 'white',
                                }}
                            >
                                Default Value
                            </TableCell>
                            <TableCell
                                style={{ 
                                    textAlign: 'center', 
                                    color: 'white',
                                    borderTopRightRadius: 4 
                                }}
                            >
                                Delete
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formFields.map( (field, fieldIndex) => (
                            <CreateFormField 
                                field={field}
                                fieldIndex={fieldIndex}
                                handleUpdateFormFields={handleUpdateFormFields}
                                handleDeleteRow={handleDeleteRow}
                            />
                        ))}

                    </TableBody>
                    <Button 
                        disabled={!canCreateForm}
                        style={{
                            backgroundColor: '#45425a',
                            color: 'whitesmoke',
                            opacity: !canCreateForm ? 0.2 : 1,
                            marginTop: '1rem'
                        }}
                        onClick={createForm}
                    >
                        Create Form
                    </Button>
                </Table>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query siteDataQuery($sitename: String) {
        datoCmsWebsite(name: {eq: $sitename}) {
            id
        }
    }
`

export default CreateForm;
